<script setup>
import Pagination from '@/Components/Pagination/Pagination.vue';
import Table from '@/Components/Table/Table.vue';
import StaffLayout from '@/Layouts/StaffLayout.vue';
import { useForm } from '@inertiajs/vue3';
import { useDebounceFn } from '@vueuse/core';
import { computed, inject, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const route = inject('route');
const props = defineProps({
    query: Object,
    reviews: Object,

    locationOptions: Object,
});

const form = useForm({
    search: props.query.search ?? '',
    location: props.query.location ?? '',
    orderBy: props.query.orderBy ?? 'date',
    orderDir: props.query.orderDir ?? 'desc',
    page: props.query.page ?? 1,
});

const getData = (resetPage = true) => {
    form.page = resetPage ? 1 : form.page;

    form.get(
        route('staff.flexers.received-reviews.index', {
            flexer: props.query.flexer.id,
        }),
        {
            preserveState: true,
            preserveScroll: true,
            only: ['reviews', 'query'],
        }
    );
};

const debouncedGetData = useDebounceFn(() => getData(), 300);

const tableSettings = [
    {
        label: t('Date {model}', { model: 'shift' }),
        orderBy: 'start',
        width: '15%',
    },
    {
        label: t('Details {model}', { model: 'shift' }),
        from: 'lg',
        width: '20%',
    },
    { label: t('Position'), from: 'md', orderBy: 'employer', width: '20%' },
    { label: t('Employer'), from: 'md', orderBy: 'employer', width: '20%' },
    // { label: t("Flexer"), orderBy: "flexer", width: "20%" },
    // { label: t("Position"), orderBy: "position", width: "10%" },
    {
        label: t('Rating'),
        orderBy: 'rating',
        width: '10%',
        component: 'Rating',
    },
    { label: '', width: '5%' },
    { label: '', width: '5%' },
];

const tableData = computed(() =>
    props.reviews.data.map((review) => {
        return {
            id: review.id, // used as vue key if set
            route: route('staff.reviews.show', review.id),
            // colorCode: invoice.color_code, // used to set row color accent
            rowData: [
                review.date,
                review.detail,
                review.position,
                review.employer_name,
                review.rating,
                review.completed_shift_count,
            ],
        };
    })
);

const setPage = (e) => {
    form.page = e;
    getData(false);
};

onMounted(() => {
    //   getData(false);
});
</script>

<template>
    <StaffLayout
        :title="$t('Received {model}', { model: $t('reviews') })"
        :h1="$t('Received {model}', { model: $t('reviews') })"
        back-name="staff.flexers.show"
    >
        <div class="flex-between">
            <div class="flex items-center gap-4">
                <FormKit
                    type="search"
                    :placeholder="$t('Search')"
                    name="search"
                    prefix-icon="search"
                    v-model="form.search"
                    :value="form.search"
                    @input="debouncedGetData"
                />

                <FormKit
                    type="select"
                    name="employer"
                    :options="{
                        '': $t('All {attribute}', { attribute: $t('employers') }),
                        ...locationOptions,
                    }"
                    select-icon="down"
                    v-model="form.location"
                    :value="form.location"
                    @input="getData"
                />
            </div>
            <div class="flex items-center gap-4">
                <FormKit
                    type="select"
                    name="per_page"
                    :options="{
                        ...paginationLengthOptions,
                    }"
                    v-model="form.per_page"
                    select-icon="down"
                    :value="form.per_page"
                    @input="
                        getData();
                        setPerPage();
                    "
                />
            </div>
        </div>

        <template v-if="typeof reviews !== 'undefined' && reviews.data.length > 0">
            <Table
                class="mb-8"
                :loading="form.processing"
                :tableSettings="tableSettings"
                :data="tableData"
                v-model:orderBy="form.orderBy"
                @update:orderBy="getData()"
                v-model:orderDir="form.orderDir"
                @update:orderDir="getData()"
            />

            <pagination
                v-if="typeof reviews !== 'undefined'"
                :links="reviews.links"
                @setPage="setPage"
                :hideTotal="query.search != '' && query.search != null"
            />
        </template>
        <template v-else>
            <div class="w-full px-4 py-3 overflow-hidden font-bold leading-tight bg-white rounded">
                {{ $t('No {model} found', { model: $t('reviews') }) }}
            </div>
        </template>
    </StaffLayout>
</template>
